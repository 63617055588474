.SpecComment {
	display: flex;
	flex-direction: column;

	width: stretch;

	border-bottom: 1px solid #e0e0e0;

	margin-left: 1em;
	margin-right: 1em;
}

.specCommentContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	gap: 1em;
}

.specCommentHeader {
	display: flex;
	flex-direction: row;

	gap: 1em;
}

.specCommentHeader img {
	height: 40px;
	aspect-ratio: 1/1;

	border-radius: 50%;
}

.specCommentTitlesContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;

	gap: 0.25em;
}

.specCommentBody {
	display: flex;
	flex-direction: column;

	width: stretch;
}

.specCommentSubmitter {
	font-size: 14px;
	font-weight: 400;

	margin: 0;
}

.specCommentDate {
	font-size: 12px;
	font-weight: 300;

	margin: 0;
}

.specCommentText {
	font-size: 14px;
	font-weight: 300;

	word-wrap: break-word;
	hyphens: auto;
}
