.AssembliesSearchBar {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	width: stretch;

	padding-top: 1em;
	padding-bottom: 1em;
}

.assembliesSearchBarFieldsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 1em;

	width: 60%;
}
