.J2NoResultsFound {
    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 2em;
    padding-bottom: 2em;

    font-size: 24px;
    font-weight: 500;
    color: #9e9e9e;
}