.J2PageNotFound {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100vh;
}

.imageContainer img {
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    border-radius: 0.5em;
}

.pageNotFoundTitle {
    font-size: 36px;
    font-weight: 500;

    margin-top: 1em;
    margin-bottom: 1em;
}

.notFoundMessage {
    font-size: 20px;
    font-weight: 400;

    margin-top: 1em;
    margin-bottom: 1em;
}