.DesignJobDetailScreen {
	display: flex;
	flex-direction: column;

	height: 100%;

	margin-left: 2em;
	margin-right: 2em;
}

.designJobDetailScreenHeader {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	height: 15vh;
}

.designJobDetailScreenHeaderTitle {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;

	font-size: 36px;
	font-weight: 500;

	height: 10vh;
}

.designJobDetailScreenBody {
	display: flex;
	flex-direction: column;

	height: 85vh;

	padding: 2vh 1em;

	overflow-y: scroll;
}

.loadingDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 85vh;

	padding: 2em;
}
