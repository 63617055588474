#SpecTextEditorInfoDialogTitle {
	background-color: #184c93;
	color: white;
}

#SpecTextEditorInfoDialogContent {
	display: flex;
	flex-direction: column;
	gap: 1em;

	padding-top: 1em;
	padding-bottom: 1em;
}

.SpecTextEditorInfoButton {
	display: flex;
	justify-content: flex-end;
}

.infoItem {
	display: flex;
	justify-content: space-between;
	align-items: center;

	height: 7.5em;
}

.infoItemText {
	display: flex;
	flex-direction: column;
	gap: 0.5em;
}

.infoItemTitle {
	font-size: 16px;
	font-weight: 500;

	color: #222222;

	margin: 0;
}

.infoItemSubtitle {
	font-size: 14px;
	font-weight: 400;

	color: #7d7d7d;

	margin: 0;
}
