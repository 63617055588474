.CoreRepairScopesSearchBar {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	width: stretch;

	height: fit-content;

	padding-top: 1em;
	padding-bottom: 1em;
}

.coreRepairScopesSearchFieldsContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 1em;

	width: stretch;
}
