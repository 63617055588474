#editTicketDialogTitle {
    background-color: #184C93;
    color: white;

    display: flex;
    justify-content: space-between;
}

#editTicketDialogContent {
    display: flex;
    flex-direction: column;

    gap: 1em;

    padding-top: 1em;
    padding-bottom: 1em;
}

.editTicketDialogRow {
    display: flex;
    justify-content: space-between;
    gap: 1em;
}

.editTicketDialogAlerts {
    display: flex;
    flex-direction: column;
}


.dialogButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;

    margin: 0;
}
