.SpecBookLinkItem {
	display: flex;
	justify-content: space-between;
	align-items: center;

	height: 7.5em;
}

.specBookLinkItemText {
	display: flex;
	flex-direction: column;
	gap: 0.5em;
}

.specBookLinkItemTitle {
	font-size: 16px;
	font-weight: 500;

	color: #222222;

	margin: 0;
}

.specBookLinkItemSubtitle {
	font-size: 14px;
	font-weight: 400;

	color: #7d7d7d;

	margin: 0;
}

.specBookLinkItemUserContainer {
	display: flex;

	gap: 0.5em;
}

.specBookLinkItemUserPFPContainer {
	display: flex;
}

.specBookLinkItemUserPFPContainer img {
	height: 40px;
	aspect-ratio: 1/1;

	border-radius: 50%;
}

.specBookLinkItemUser {
	display: flex;
	flex-direction: column;
	justify-content: center;

	gap: 0.25em;
}

.specBookLinkItemSubmitter {
	font-size: 14px;
	font-weight: 400;

	margin: 0;
}

.specBookLinkItemDate {
	font-size: 12px;
	font-weight: 300;

	margin: 0;
}
