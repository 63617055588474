/* #reportIssueDialog {
    
} */

#reportIssueDialogContent {
    display: flex;
    flex-direction: column;

    gap: 0.5em;
}

.dialogButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 0.5em;

    margin-top: 0.5em;
}

.loadingSpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}