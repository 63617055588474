.InactiveTickets {
    display: flex;
    flex-direction: column;

    padding-left: 2em;
    padding-right: 2em;
}

.inactiveTicketsHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    font-size: 36px;
    font-weight: 500;

    height: 10vh;

    margin-bottom: 2vh;
}

.inactiveTicketsBody {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    padding-bottom: 2em;
}


.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}