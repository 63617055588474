#editInformationCardDialogTitle {
    background-color: #184C93;
    color: white;

}

#editInformationCardDialogContent {
    display: flex;
    flex-direction: column;
    gap: 1em;

    padding-top: 1em;
    padding-bottom: 1em;
}

.editInformationCardDialogRow {
    display: flex;
    justify-content: space-between;
    gap: 1em;
}


.cadFileContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;

    width: stretch;
}

.cadFileLabel {
    font-size: 16px;
    font-weight: 500;
}

.cadFileInfo {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.fileLabel {
    font-size: 16px;
    font-weight: 400;
}





.dialogButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;

    margin: 0;
}


.loadingSpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}