.Login {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
    width: 100vw;

    background-color: #f4f4f4;
    
    background-image: url('../../images/sample_site_photo.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.loginCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5em;

    padding: 2em 1em;

    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}



@media (min-width: 320px) and (max-width: 767px) {
    .loginCard {
        gap: 2em;
    
        width: 80%;
        padding: 2em 1em;
    }

	#loginLogo {
        width: 80%;
    }

    #loginButton {
        width: 80%;
        text-align: center;
        font-size: 12px;
    }
}


@media (min-width: 768px) and (max-width: 1023px) {
    .loginCard {
        gap: 3em;
    
        width: 80%;
        padding: 2em 1em;
    }

	#loginLogo {
        width: 80%;
    }

    #loginButton {
        height: 5vh;
        width: 80%;
        text-align: center;
        font-size: 2vh;
    }
}


@media (min-width: 1024px) and (max-width: 1219px) {
    .loginCard {
        gap: 3em;
    
        width: 80%;
        padding: 3em 1em;
    }

	#loginLogo {
        width: 80%;
    }

    #loginButton {
        height: 5vh;
        width: 80%;
        text-align: center;
        font-size: 2vh;
    }
}


@media (min-width: 1220px) and (max-width: 1439px) {

}


@media (min-width: 1440px) {
	
}