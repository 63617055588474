#editCategoryDialogTitle {
    background-color: #184C93;
    color: white;

    display: flex;
    justify-content: space-between;
}

#editCategoryDialogContent {
    display: flex;
    flex-direction: column;

    padding-top: 1em;
    padding-bottom: 1em;
}

.editCategoryDialogRow {
    display: flex;
    justify-content: space-between;
    gap: 1em;

    padding-top: 1em;
    padding-bottom: 1em;
}


.editCategoryDialogAlerts {
    display: flex;
    flex-direction: column;
}


.dialogButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;

    margin: 0;
}


.submitButtonContainer {
    position: relative;
}

.confirmDeleteContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.confirmDeleteHeader {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}


.confirmDeleteButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;
}
