.SpecBookLinksCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-top: 1vh;
	margin-bottom: 1vh;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.specBookLinksCardHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: stretch;
}

.specBookLinksCardHeaderIconTitleContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5em;

	flex-grow: 1;
}

.specBookLinksCardHeaderTitle {
	font-size: 20px;
	font-weight: 500;

	color: #f2bc77;

	margin: 0;
}

.specBookLinksCardContent {
	display: flex;
	flex-direction: column;
	gap: 1em;

	width: stretch;

	padding: 1em;
}
