.CommentsCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-top: 1vh;
	margin-bottom: 1vh;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.commentsCardHeader {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5em;

	width: stretch;

	cursor: pointer;
}

.commentsCardHeaderTitle {
	font-size: 20px;
	font-weight: 500;

	color: #f2bc77;

	margin: 0;
}

#commentsDropdownCollapse,
#newCommentDropdownCollapse {
	width: stretch;
}

.commentsDropdownContent {
	display: flex;
	flex-direction: column;

	width: stretch;

	padding-top: 1em;
	/* padding-bottom: 1em; */
}

.newCommentButtonContainer {
	display: flex;
	align-items: center;

	width: stretch;

	margin-left: 1em;
	margin-bottom: 1em;
}

.newCommentInputContainer {
	display: flex;
	flex-direction: column;
	gap: 1em;

	width: stretch;

	padding: 0.5em 1em;
}

.newCommentRow {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 1em;

	width: stretch;
}

.commentsContainer {
	display: flex;
	flex-direction: column;
	gap: 1em;

	width: stretch;
}

.noCommentsText {
	padding: 0em 1em;
	color: #808080;
}
