#editDashboardAccessDialogTitle {
    background-color: #184C93;
    color: white;

}

#editDashboardAccessDialogContent {
    display: flex;
    flex-direction: column;
    gap: 1em;

    padding-top: 1em;
    padding-bottom: 1em;
}

.editDashboardAccessDialogRow {
    display: flex;
    justify-content: space-between;
    gap: 1em;
}


.dialogButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;

    margin: 0;
}


.loadingSpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}