.DropboxInformationCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;

    width: stretch;

    padding: 1em;
    
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}

.dropboxCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.25em;

    width: stretch;

    font-size: 20px;
    font-weight: 500;

    color: #333333;
}

.dropboxCardSubheader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: stretch;

    font-size: 16px;
    font-weight: 500;

    color: #333333;
}

.filePathContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;

    width: stretch;
}

.filePathContainer h1 {
    font-size: 16px;
    font-weight: 500;

    color: #333333;

    padding: 0em;
    margin: 0em;
}




.filterDropboxFolderTextFieldContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;

    width: stretch;
}

.resultsContainer {
    display: flex;
    flex-direction: column;

    width: stretch;
}

.noResultsFoundContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 24px;
    font-weight: 500;

    min-height: 40vh;
    max-height: 40vh;
    width: stretch;


    background-color: #EEEEEE;
    color: #9e9e9e;
}

.folderLoadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #EEEEEE;

    height: 40vh;
    width: stretch;
}

.folderContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    padding: 1em;
    border-radius: 0.5em;

    background-color: #EEEEEE;

    min-height: 40vh;
    max-height: 40vh;
    width: stretch;

    overflow-y: scroll;
}


.dropboxFileMessageContainer {
    display: flex;
    flex-direction: column;

    width: stretch;

    font-size: 16px;
    font-weight: 400;
    text-align: center;

    color: #333333;
}