#tableContainer {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	overflow-x: auto;

	display: flex;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	border-radius: 0.5em;

	display: flex;
	justify-content: center;
	min-width: 0;
	min-height: 70vh;
	max-height: 70vh;
	overflow: scroll;
}

#tableContainer::-webkit-scrollbar {
	display: none; /* Chrome, Safari, & Opera */
}

#table {
	width: 100%;
}

#tableHeaderCell {
	color: #ffffff;
	font-weight: bold;
}

#tableRowCell {
	color: #000000;
}

#tableCellContents {
	display: flex;
	align-items: center;
}

.default {
	color: #000000;
}

.notApplicable {
	color: gray;
	font-style: italic;
}

.link {
	color: #007bff;
	text-decoration: underline;
	cursor: pointer;
}
