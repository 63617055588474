#CoreRepairScopeEditAddDialogTitle {
	background-color: #184c93;
	color: white;
}

#CoreRepairScopeEditAddDialogContent {
	display: flex;
	flex-direction: column;
	gap: 1em;

	padding-top: 1em;
	padding-bottom: 1em;
}

.editCoreRepairScopeButtons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 1em;

	margin: 0;
}

.addSpecificationRowOne {
	display: flex;
}

.addSpecificationDivision {
	color: gray;
	/* padding-right: 0.25em; */
}

.addSpecificationDash {
	padding-right: 0.25em;
	padding-left: 0.25em;
}

.addSpecificationDescription {
	font-size: 0.875rem;
	color: gray;
}
