.DetailComment {
    display: flex;
    flex-direction: column;

    width: stretch;
    
    border-bottom: 1px solid #e0e0e0;

    margin-left: 1em;
    margin-right: 1em;
}

.detailCommentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 1em;
}


.detailCommentHeader {
    display: flex;
    flex-direction: row;

    gap: 1em;
}

.detailCommentHeader img {
    height: 40px;
    aspect-ratio: 1/1;

    border-radius: 50%;
}


.detailCommentTitlesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 0.25em;
}


.detailCommentBody {
    display: flex;
    flex-direction: column;

    width: stretch;
}


.detailCommentSubmitter {
    font-size: 14px;
    font-weight: 400;

    margin: 0;
}

.detailCommentDate {
    font-size: 12px;
    font-weight: 300;

    margin: 0;
}

.detailCommentText {
    font-size: 14px;
    font-weight: 300;
}

