#DevicesServiceHistoryDialogTitle {
	background-color: #184c93;
	color: white;
}

#DevicesServiceHistoryDialogContent {
	display: flex;
	flex-direction: column;
	gap: 1em;

	padding-top: 1em;
	padding-bottom: 1em;
	max-height: 80vh;
	overflow-y: auto;
}

.DevicesServiceHistoryFields {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1em;
}

.DevicesServiceHistoryCloseButtons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	margin-top: 1em;
	gap: 1em;
	margin: 0;
}

.DevicesServiceHistoryLoadingSpinner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
	width: 50vw;
}
