.PendingChanges {
	display: flex;
	flex-direction: column;

	height: 100%;

	padding-left: 2em;
	padding-right: 2em;
}

.pendingChangesHeader {
	display: flex;
	flex-direction: column;

	height: 10vh;

	margin-bottom: 2vh;
}

.pendingChangesHeaderTitle {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	font-size: 36px;
	font-weight: 500;

	height: 10vh;
}

#pendingSpecCardLink {
	text-decoration: none;
	color: #000;

	width: stretch;
}

#pendingSpecCardLink:visited {
	color: #000;
}

.pendingChangesPreviewContainer {
	display: flex;
	flex-direction: column;
	gap: 1em;

	margin-top: 1vh;
}
