.TicketCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: stretch;

	margin-top: 1vh;
	margin-bottom: 1vh;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.ticketCardHeader {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	width: stretch;
}

.ticketCardHeaderTitleContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 0.5em;
}

.ticketCardHeaderTitle {
	font-size: 20px;
	font-weight: 600;
	color: #184c93;

	margin-top: 0;
	margin-bottom: 0;
}

.ticketCardBody {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: stretch;
}

.ticketCardBodyRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	width: stretch;

	margin-top: 1em;
	margin-bottom: 1em;
}

.ticketCardBodyRowItem {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5em;

	flex: 1;
}

.ticketCardBodyRowItemTitle,
.ticketCardBodyRowItemValue {
	font-size: 16px;
	font-weight: 400;

	margin-top: 0;
	margin-bottom: 0;
}

.ticketCardBodyRowItemTitle {
	font-weight: 500;
	color: #184c93;
}

.screenshotContainer {
	display: flex;
	flex-direction: column;
	gap: 1em;

	margin-top: 1em;
	margin-bottom: 1em;
}

.screenshotContainer img {
	width: 50%;
	height: auto;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	border-radius: 0.5em;
}

.commentsContainer {
	display: flex;
	flex-direction: column;
	gap: 1em;

	width: stretch;

	margin-top: 1em;
	margin-bottom: 1em;
}

.dialogButtons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 1em;

	width: stretch;

	margin-top: 1em;
}

#confirmMarkTicketDialogTitle {
	background-color: #184c93;
	color: white;

	display: flex;
	justify-content: space-between;
}

#confirmMarkTicketContent {
	display: flex;
	flex-direction: column;

	gap: 1em;

	padding-top: 1em;
	padding-bottom: 1em;
}
