.PendingSpecChangeReviewScreen {
	display: flex;
	flex-direction: column;

	height: 100%;

	padding-left: 2em;
	padding-right: 2em;
}

.PendingSpecChangeReviewScreen {
	flex-grow: 1;

	height: 100vh;
	overflow-y: scroll;

	/* IE, Edge, and Firefox */
	-ms-overflow-style: auto;
	scrollbar-width: auto;
}

.PendingSpecChangeReviewScreen::-webkit-scrollbar {
	/* Chrome, Safari, & Opera */
	display: auto;
}

.pendingSpecChangeReviewScreenHeader {
	display: flex;
	flex-direction: column;

	height: 10vh;

	margin-bottom: 2vh;
}

.pendingSpecChangeReviewScreenHeaderTitle {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	font-size: 36px;
	font-weight: 500;

	height: 10vh;
}

.pendingSpecChangeReviewScreenLoadingContainer {
	flex-grow: 1;

	/* These 3 lines centers the loading circle */
	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 2em;
	padding-right: 2em;
}
