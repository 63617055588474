.PendingJobReviewScreen {
    display: flex;
    flex-direction: column;

    padding-left: 2em;
    padding-right: 2em;
}

.pendingJobReviewScreenHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    font-size: 36px;
    font-weight: 500;

    height: 10vh;

    margin-bottom: 2vh;
}

.pendingJobAlertCollapseContainer {
    display: flex;
    width: stretch;

    padding-top: 1em;
    padding-bottom: 1em;
}

.progressBarCollapseContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;

    width: stretch;

    padding: 1em 2em;
}

.progressBarCollapseContainer h2 {
    font-size: 18px;
    font-weight: 500;

    margin: 0;
    padding: 0;

    color: #535353;
}


#pendingJobProgressBar {
    width: stretch;
}


.jobCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    margin-bottom: 0;
}


.pendingJobsButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1em;

    width: stretch;
}

.pendingJobLoadingSpinner {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 10vh; */
}

