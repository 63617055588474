.AssembliesAndGroupingsCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 1vh;
    margin-bottom: 1vh;

    padding: 1em;
    
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}


.assembliesAndGroupingsCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: stretch;
}

.assembliesAndGroupingsCardHeaderIconTitleContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    
    flex-grow: 1;

    cursor: pointer;
}

.assembliesAndGroupingsCardHeaderTitle {
    font-size: 20px;
    font-weight: 500;

    color: #FAAC4A;

    margin: 0;
}


#assembliesAndGroupingsDropdownCollapse {
    width: stretch;
}

.assembliesAndGroupingsDropdownContent {
    display: flex;
    flex-direction: row;
    gap: 2em;

    width: stretch;

    padding: 1em;
}

.agContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    flex: 1;
}

.agTitle {
    font-size: 16px;
    font-weight: 500;

    margin: 0;
}

.agItem {
    font-size: 16px;
    font-weight: 300;

    margin: 0;
}