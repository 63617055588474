#CoreRepairScopeDeleteDialogTitle {
	background-color: #184c93;
	color: white;
}

#CoreRepairScopeDeleteDialogContent {
	display: flex;
	flex-direction: column;
	gap: 1em;

	padding-top: 1em;
	padding-bottom: 1em;
}

#CoreRepairScopeDeleteDialogButtons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 1em;

	margin: 0;
}
