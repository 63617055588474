.App {
	display: flex;
	flex-direction: row;

	background-color: #f4f4f4;
}

.nav {
	height: 100vh;
	background-color: #222222;
}

.app {
	flex-grow: 1;

	height: 100vh;
	overflow-y: scroll;

	/* IE, Edge, and Firefox */
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.app::-webkit-scrollbar {
	/* Chrome, Safari, & Opera */
	display: none;
}

.loading {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

#loading-logo {
	height: 100px;
	width: auto;
}

@media (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
	body {
		margin: 0;
		padding: 0;

		overflow: hidden;
	}

	.App {
		display: flex;
		flex-direction: row;

		height: 100svh;
		min-height: 100svh;
		max-height: 100svh;

		background-color: #f4f4f4;

		overflow: hidden;

		position: fixed;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.nav {
		height: 100svh;
		min-height: 100svh;
		max-height: 100svh;

		width: 20vw;
		min-width: 20vw;
		max-width: 20vw;

		background-color: #222222;
	}

	.app {
		flex-grow: 1;

		width: 80vw;
		min-width: 80vw;
		max-width: 80vw;

		height: 100svh;
		min-height: 100svh;
		max-height: 100svh;

		overflow-y: scroll;
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	.app::-webkit-scrollbar {
		display: none; /* Chrome, Safari, & Opera */
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
}

@media (min-width: 1024px) and (max-width: 1219px) {
}
