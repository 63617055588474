.DashboardCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: stretch;

    margin-top: 1vh;
    margin-bottom: 1vh;

    padding: 1em;
    
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}

/* .dashboardCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: stretch;
}

.dashboardCardHeaderTitles {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.5em;

    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.dashboardCardHeaderTitles h2 {
    font-size: 20px;
    font-weight: 500;

    color: black;

    margin: 0;
}

.dashboardCardHeaderTitles h3 {
    font-size: 16px;
    font-weight: 400;

    color: #3C4142;

    margin: 0;
} */

.dashboardCardHeader {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    width: stretch;

    margin-top: 0.5em;
    margin-bottom: 0.5em;

    cursor: pointer;
}

.leftSideDashboardCardHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
}

.dashboardCardTitles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
}

.dashboardCardTitles h2 {
    font-size: 20px;
    font-weight: 500;

    margin: 0
}

.dashboardCardTitles h3 {
    font-size: 16px;
    font-weight: 400;

    color: #3C4142;
    margin: 0;
}







#dashboardiframeContainerCollapse {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
}

.dashboardiframeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: stretch;
    /* height: auto; */

    margin-top: 1.5em;
    margin-bottom: 1.5em;
}