.Jobs {
	display: flex;
	flex-direction: column;

	padding-left: 2em;
	padding-right: 2em;
}

.jobsHeader {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	font-size: 36px;
	font-weight: 500;

	height: 10vh;

	margin-bottom: 2vh;
}

.jobCardContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1em;

	margin-bottom: 2em;
}

.pendingJobsButtons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	gap: 1em;

	width: stretch;

	margin-top: 1em;

	margin-bottom: 2em;
}

.pendingJobLoadingSpinner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 10vh;
}

#bqeCardCollapseSubheader {
	margin-bottom: 1em;
}

.bqeCardRow {
	display: flex;
	justify-content: space-between;
	gap: 1em;

	width: stretch;
}

.bqeLoadingCardRow {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1em;

	width: stretch;
}

#projectSectionCollapse {
	display: flex;
	flex-direction: column;
	gap: 1em;

	width: stretch;
}
