.PendingJobCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;

    width: stretch;

    margin-top: 1vh;
    margin-bottom: 1vh;

    padding: 1em;
    
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}

.PendingJobCard:hover {
    cursor: pointer;
}

.pendingJobCardTitle {
    font-size: 20px;
    font-weight: 500;
}

.pendingJobCardTags {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}


.oicTag, .dateSignedTag, .dealOwnerTag {
    font-size: 14px;
    font-weight: 500;

    padding: 0.5em;
    border-radius: 0.5em;

    color: #FFFFFF;
}

.oicTag {
    background-color: #184C93;
}

.dateSignedTag {
    background-color: #FAAC4A;
}

.dealOwnerTag {
    background-color: #3C4142;
}