.InfoCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: stretch;

	margin-top: 1vh;
	margin-bottom: 1vh;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.infoCardHeader {
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	width: stretch;

	/* margin-top: 0.5em;
	margin-bottom: 0.5em; */

	cursor: pointer;
}

.leftSideInfoCardHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 1em;
}

.infoCardTitles {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5em;
}

.infoCardTitles h2 {
	font-size: 20px;
	font-weight: 500;

	margin: 0;
	padding: 0;
}

.infoCardTitles h3 {
	font-size: 16px;
	font-weight: 400;
	color: #666666;

	margin: 0;
	padding: 0;
}

#infoiframeContainerCollapse {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
}

.infoiframeContainer {
	display: flex;
	align-items: center;
	justify-content: center;

	width: stretch;

	margin-top: 1.5em;
	margin-bottom: 1.5em;
}
