@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=PT+Sans+Narrow:wght@400;700&display=swap");

.EmailSignature {
	display: flex;
	flex-direction: column;

	height: 100%;

	padding-left: 2em;
	padding-right: 2em;
}

.emailSignatureTitle {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	font-size: 36px;
	font-weight: 500;

	height: 10vh;

	margin-bottom: 2vh;
}

.emailSignatureFormCard,
.emailSignaturePreviewCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 1em;

	margin-top: 1vh;
	margin-bottom: 1vh;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.emailSignatureCardHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: stretch;

	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.emailSignatureCardHeader h2 {
	font-size: 20px;
	font-weight: 400;

	margin: 0;
}

.emailSignatureFormCardRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: space-between;
	gap: 1em;

	width: stretch;
}

.signaturePreview {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1em;

	width: stretch;
}

.emailSignatureDescription h3 {
	font-size: 16px;
	font-weight: 400;
	font-family: "Open Sans", sans-serif;
	text-align: center;

	color: #333333;
}
