.BQEInformationCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: stretch;

    padding: 1em;
    
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}

.bqeCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.25em;

    width: stretch;

    font-size: 20px;
    font-weight: 500;

    color: #333333;
}

.bqeCardSubheader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: stretch;
}

.bqeCardSubheader h1 {
    font-size: 16px;
    font-weight: 400;

    color: #333333;

    margin: 0;
    padding: 0;
}


#bqeCardCollapseSubheader {
    margin-top: 1em;
}

.collapseContainerDiv {
    display: flex;
    flex-direction: column;
    
    gap: 1em;

    padding-top: 1em;
    padding-bottom: 1em;

    width: stretch;
}


.bqeCardRow {
    display: flex;
    justify-content: space-between;
    gap: 1em;

    width: stretch;
}

.bqeLoadingCardRow {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;

    width: stretch;
}


#projectSectionCollapse, #searchExistingPropertyCollapse, #createNewPropertyCollapse, #clientSectionCollapse {
    display: flex;
    flex-direction: column;
    width: stretch;
}