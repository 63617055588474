#specificationCommentsDialogTitle {
	background-color: #184c93;
	color: white;
}

#specificationCommentsDialogContent {
	display: flex;
	flex-direction: column;
	/* gap: 1em; */

	padding-top: 1em;
	padding-bottom: 1em;
}

.specificationCommentsDialogRow {
	display: flex;
	justify-content: space-between;
	gap: 1em;
}

.specificationCommentsDialogCommentContainer {
	display: flex;
	flex-direction: column;
	gap: 1em;

	padding-top: 1em;
	margin: 1em 0em;

	max-height: 40vh;
	overflow-y: scroll;
}

.newSpecCommentButtonContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: stretch;

	margin-top: 1em;
}

.specificationCommentsDialogAlertContainer {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: stretch;
}

.newSpecCommentInputContainer {
	display: flex;
	flex-direction: column;
	gap: 1em;

	padding-top: 1em;

	width: stretch;
}

.newSpecCommentRow {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 1em;

	width: stretch;
}

#newSpecCommentDropdownCollapse {
	width: stretch;
}

.dialogButtons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 1em;

	margin: 0;
}

.loadingSpinnerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}
