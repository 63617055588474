#ServiceHistoriesEditAddDialogTitle {
	background-color: #184c93;
	color: white;
}

#ServiceHistoriesEditAddDialogContent {
	display: flex;
	flex-direction: column;
	gap: 1em;

	padding-top: 1em;
	padding-bottom: 1em;
	max-height: 80vh;
	overflow-y: auto;
}

.editServiceHistoriesButtons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 1em;
	margin: 0;
}

.FormBlock {
	display: flex;
	flex-direction: column;
	gap: 1em;
}

.FormRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	gap: 1em;
}

.dialogSubtitle {
	font-weight: 500;
}

.serviceHistoriesLoadingSpinner {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
	width: 50vw;
}
