.specificationsListCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-top: 1vh;
	margin-bottom: 1vh;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.specificationsListCardHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: stretch;
}

.specificationsListCardHeaderIconTitleContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5em;

	flex-grow: 1;
}

.specificationsListCardHeaderTitle {
	font-size: 20px;
	font-weight: 500;

	color: #184c93;

	margin: 0;
}

.specificationsListCardHeaderButtons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1em;
}

#specificationsListCardDropdownCollapse {
	width: stretch;
}

.alertContainer {
	padding-top: 1em;
	padding-bottom: 1em;
}

.specificationsListCardContent {
	display: flex;
	flex-direction: column;
	gap: 2em;

	width: stretch;

	padding-top: 1em;
	padding-bottom: 1em;
}

.specificationsListCardContentRow {
	display: flex;
	justify-content: space-between;
	gap: 1em;

	width: stretch;
}

.specificationsListCardButtons {
	display: flex;
	justify-content: flex-end;
	gap: 1em;

	width: stretch;
}
