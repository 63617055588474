#EditContentCardDialogTitle {
	background-color: #184c93;
	color: white;
}

#EditContentCardDialogContent {
	/* display: flex; */
	/* flex-direction: column; */

	padding-top: 1em;
	padding-bottom: 1em;
}
