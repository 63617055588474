.FolderCard {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;

    width: stretch;

    padding: 1em;
    border-radius: 0.5em;

    background-color: #184C93;

    cursor: pointer;
}

.FolderCard h1 {
    font-size: 14px;
    font-weight: 500;

    color: #FFFFFF;

    padding: 0em;
    margin: 0em;
}