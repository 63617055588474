#thumbnailBlowupDialog {
    display: flex;
    justify-content: center;
    align-items: center;
}

#thumbnailBlowupDialogContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: fit-content;
    height: fit-content;

    padding: 1em;
    padding-bottom: 0em;
}

#thumbnailBlowupDialogContent img {
    height: 70vh;
    
    object-fit: contain;

    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    border-radius: 0.5em;
}

#thumbnailBlowupDialogContent h2 {
    font-style: italic;
    font-size: 16px;
    font-weight: 400;
    color: #9e9e9e;

    margin: 1em 0em;
}