/* .TicketComment {
    display: flex;
    flex-direction: column;

    width: stretch;
    
    border-bottom: 1px solid #e0e0e0;

    margin-left: 1em;
    margin-right: 1em;
}

.ticketCommentHeader {
    display: flex;
    flex-direction: column;

    gap: 0.25em;
}

.ticketCommentSubmitter {
    font-size: 14px;
    font-weight: 500;

    margin: 0;
}

.ticketCommentDate {
    font-size: 12px;
    font-weight: 300;

    margin: 0;
}

.ticketCommentText {
    font-size: 14px;
    font-weight: 400;
}


.ticketCommentBody {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
} */


.TicketComment {
    display: flex;
    flex-direction: column;

    width: stretch;
    
    border-bottom: 1px solid #e0e0e0;

    margin-left: 1em;
    margin-right: 1em;
}

.ticketCommentContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 1em;
}


.ticketCommentHeader {
    display: flex;
    flex-direction: row;

    gap: 1em;
}

.ticketCommentHeader img {
    height: 40px;
    aspect-ratio: 1/1;

    border-radius: 50%;
}


.ticketCommentTitlesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 0.25em;
}


.ticketCommentBody {
    display: flex;
    flex-direction: column;

    width: stretch;
}


.ticketCommentSubmitter {
    font-size: 14px;
    font-weight: 400;

    margin: 0;
}

.ticketCommentDate {
    font-size: 12px;
    font-weight: 300;

    margin: 0;
}

.ticketCommentText {
    font-size: 14px;
    font-weight: 300;
}

