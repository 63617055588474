.J2MultilineTextField {
    width: 100%;
}

#j2MultilineTextField {
    font-family: inherit;
    font-size: 16px;

    resize: none;

    width: stretch;
    padding: 16.5px 14px;

    border-radius: 4px;
    
    border-color: #CAC4D0;
}

#j2MultilineTextField::placeholder {
    color: rgb(0, 0, 0, 0.6);
}

#j2MultilineTextField:hover {
    border-color: rgb(0, 0, 0, 0.6);
    border-radius: 4px;
}


#j2MultilineTextField:focus { 
    border: 1px solid #184C93;
    outline: 1px solid #184C93;
    border-radius: 4px;
}


#j2MultilineTextField::-webkit-scrollbar {
    width: 8px;
}

#j2MultilineTextField::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 10px;
}

#j2MultilineTextField::-webkit-scrollbar-thumb {
    background: #CAC4D0;
    border-radius: 10px;
}

#j2MultilineTextField::-webkit-scrollbar-thumb:hover {
    background: #CAC4D0;
}
