.AllDashboards {
    display: flex;
    flex-direction: column;

    height: 100%;

    padding-left: 2em;
    padding-right: 2em;
}

.allDashboardsHeader {
    display: flex;
    flex-direction: column;

    height: 20vh;
}

.allDashboardsHeaderTitle {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    font-size: 36px;
    font-weight: 500;

    height: 10vh;
}



.dashboardsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: stretch;

    padding-bottom: 2em;
}



.loadingSpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}