.PendingSpecChangeComparisonCard {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	width: stretch;

	padding: 1em;
	margin-bottom: 2vh;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.pendingSpecChangeComparisonCardDescriptionContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	gap: 1em;

	border-bottom: 1px solid #9e9e9e;

	margin-bottom: 1em;
}

.pendingSpecChangeComparisonCardDescriptionHeader {
	display: flex;
	flex-direction: row;

	gap: 1em;
}

.pendingSpecChangeComparisonCardDescriptionHeader img {
	height: 40px;
	aspect-ratio: 1/1;

	border-radius: 50%;
}

.pendingSpecChangeComparisonCardDescriptionTitlesContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;

	gap: 0.25em;
}

.pendingSpecChangeComparisonCardDescriptionBody {
	display: flex;
	flex-direction: column;

	width: stretch;
}

.pendingSpecChangeComparisonCardDescriptionSubmitter {
	font-size: 14px;
	font-weight: 400;

	margin: 0;
}

.pendingSpecChangeComparisonCardDescriptionDate {
	font-size: 12px;
	font-weight: 300;

	margin: 0;
}

.pendingSpecChangeComparisonCardDescriptionText {
	font-size: 14px;
	font-weight: 300;

	word-wrap: break-word;
	hyphens: auto;
}

.pendingSpecChangeComparisonCardContent {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	width: stretch;
}

.pendingSpecChangeComparisonCardOriginalContainer,
.pendingSpecChangeComparisonCardNewContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1em;

	flex: 1;
}

.pendingSpecChangeComparisonCardOriginalHeader,
.pendingSpecChangeComparisonCardNewHeader {
	font-size: 16px;
	font-weight: 500;
}

.pendingSpecChangeComparisonNew,
.pendingSpecChangeComparisonOriginal {
	display: flex;
	flex-direction: column;

	height: 80vh;
	width: 90%;
	max-width: 90%;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #f4f4f4;

	border-radius: 0.5em;
}

.pendingSpecChangeComparisonCardButtonContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 1em;

	margin-top: 1em;

	width: stretch;
}
