.PendingJobs {
    display: flex;
    flex-direction: column;

    padding-left: 2em;
    padding-right: 2em;
}

.pendingJobsHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    font-size: 36px;
    font-weight: 500;

    height: 10vh;

    margin-bottom: 2vh;
}


.pendingJobsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: stretch;
}

#pendingJobCardLink {
    text-decoration: none;
    color: #000;

    width: stretch;
}

#pendingJobCardLink:visited {
    color: #000;
}