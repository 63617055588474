.RFA {
	display: flex;
	flex-direction: column;

	padding-left: 2em;
	padding-right: 2em;
}

.rfaHeader {
	display: flex;
	align-items: flex-end;

	font-size: 36px;
	font-weight: 500;

	height: 10vh;

	margin-bottom: 2vh;
}

.cardHeader {
	display: flex;
	align-items: center;
	justify-content: space-between;

	width: stretch;

	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.cardHeader h2 {
	font-size: 20px;
	font-weight: 500;

	margin: 0;
}

.rfaCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-top: 1vh;
	margin-bottom: 1vh;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

/* For whatever reason margins don't work right in Collapse (MUI React) containers */
#clientInformationDiv,
#scopeOfServicesDiv {
	margin-bottom: 2vh;
}

.rfaCardRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: space-between;
	gap: 1em;

	width: 100%;

	margin-top: 1em;
	margin-bottom: 0.5em;
}

.searchByRFACardRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1em;

	width: 100%;

	margin-top: 1em;
	margin-bottom: 0.5em;
}

.rfaServiceTextFields {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 1em;

	width: stretch;

	margin-top: 1em;
	margin-bottom: 1em;
}

.rfaServiceTextFieldsRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: 1em;

	width: 100%;
}

.rfaButtonContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 1em;

	width: 100%;

	margin-top: 1em;
	margin-bottom: 1em;
}

.rfaSubmitButtonContainer {
	position: relative;
}
