.detailScreenListCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 1vh;
    margin-bottom: 1vh;

    padding: 1em;
    
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}

.detailScreenListCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: stretch;
}

.detailScreenListCardHeaderIconTitleContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    
    flex-grow: 1;

    cursor: pointer;
}

.detailScreenListCardHeaderTitle {
    font-size: 20px;
    font-weight: 500;

    color: #184C93;

    margin: 0;
}


#informationDropdownCollapse {
    width: stretch;
}

.informationDropdownContent {
    display: flex;
    flex-direction: column;
    gap: 2em;

    width: stretch;

    padding-top: 1em;
    padding-bottom: 1em;
}

.informationDropdownContentRow {
    display: flex;
    justify-content: space-between;

    padding-left: 2em;
    padding-right: 2em;

    width: stretch;
}

.informationDropdownContentRowItem {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    width: stretch;
}

.informationDropdownContentRowItemTitle {
    font-size: 16px;
    font-weight: 500;
}

.informationDropdownContentRowItemValue {
    font-size: 16px;
    font-weight: 300;
}


.recentCommentsContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;

    padding-top: 1em;

    width: stretch;
}


.downloadButtonContainer {
    padding-top: 0.5em;
}


.noCommentsText {
    padding: 0em 1em;
    color: #808080;
}