.PendingSpecChangePreviewCard {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;

	width: stretch;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.PendingSpecChangePreviewCard:hover {
	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.5);
	transition: box-shadow 0.2s;

	cursor: pointer;
}

.pendingSpecChangePreviewCardInfo {
	display: flex;
	flex-direction: column;
	gap: 0.5em;
}

.pendingSpecChangePreviewCardTitleContainer {
	display: flex;

	gap: 0.5em;
}

.pendingSpecChangePreviewCardTitle {
	font-size: 20px;
	font-weight: 500;
}

.pendingSpecChangePreviewCardDivision {
	font-size: 20px;
	font-weight: 400;

	color: gray;
}

.pendingSpecChangePreviewCardDescription {
	font-size: 16px;
	font-weight: 400;

	color: #444444;
}

.pendingSpecChangePreviewCardSubmitterContainer {
	display: flex;

	gap: 0.5em;
}

.pendingSpecChangePreviewCardPFPContainer {
	display: flex;
}

.pendingSpecChangePreviewCardPFPContainer img {
	height: 40px;
	aspect-ratio: 1/1;

	border-radius: 50%;
}

.pendingSpecChangePreviewCardUser {
	display: flex;
	flex-direction: column;
	justify-content: center;

	gap: 0.25em;
}

.pendingSpecChangePreviewCardSubmitter {
	font-size: 14px;
	font-weight: 400;

	margin: 0;
}

.pendingSpecChangePreviewCardDate {
	font-size: 12px;
	font-weight: 300;

	margin: 0;
}

.pendingSpecChangePreviewCardStatus {
	border-radius: 1em;
	padding: 0.5em 1em;

	color: white;
	font-size: 16px;
	font-weight: 500;
}
