.TipsAndTricks {
	display: flex;
	flex-direction: column;

	padding-left: 2em;
	padding-right: 2em;
}

.tipsAndTricksHeader {
	font-size: 36px;
	font-weight: 500;
}

.tipsAndTricksContainer {
	display: flex;
	flex-direction: column;
	align-items: center;

	width: stretch;
	margin-bottom: 2em;
}

#tipsAndTricksPreviewContainer {
	width: stretch;
}

.categoryTitle {
	font-size: 24px;
	font-weight: 500;
	margin-top: 1em;
	margin-bottom: 1em;
	margin-right: 0.5em;
	/* Added margin for spacing between title and icon */
	float: left;
}

.loadingSpinnerContainer {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 88vh;
}

#iframeElement {
	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.cardTitles {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5em;
}

.categoryHeader {
	display: flex;
}

.tipsAndTricksHeaderGroup {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	font-size: 36px;
	font-weight: 500;

	height: 10vh;
}
