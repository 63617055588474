.Navigation {
	height: 100%;
	width: 100%;
	background-color: #222222;

	display: flex;
	flex-direction: column;

	width: 17.5vw;
	min-width: 17.5vw;
	max-width: 17.5vw;

	color: white;
}

#nav-collapse {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	height: 100%;
}

/* NAV COMPRESSION/EXPANSION */
#nav-list::-webkit-scrollbar {
	display: none; /* Chrome, Safari, & Opera */
}

#nav-list {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	height: 100%;
	width: 100%;
	overflow-y: scroll;

	transition: width 0.25s;
}

#nav-list-compressed::-webkit-scrollbar {
	display: none; /* Chrome, Safari, & Opera */
}

#nav-list-compressed {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* align-items: center; */

	height: 100%;
	width: 5vw;
	overflow-y: scroll;

	transition: width 0.25s;
}

#nav-logo-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

#nav-logo {
	height: 100px;
	width: auto;

	transition: height 0.25s;
}

#nav-list-compressed #nav-logo {
	height: 75px;
	width: auto;

	transition: height 0.25s;
}

#nav-dropdown-collapse,
#nav-dropdown-collapse-rfp {
	padding-left: 1em;
}

#nav-list-item-button {
	width: stretch;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
}

.nav-list-item-text {
	flex: 1;
	display: flex;

	white-space: nowrap;

	transition: flex 0.5s;
}

#nav-list-compressed .nav-list-item-text {
	flex: 0;
	display: none;

	transition: flex 0.5s;
}

.dropdownIconContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

#nav-list-compressed .dropdownIconContainer {
	display: none;
}

@media (min-width: 320px) and (max-width: 767px) {
	.Navigation {
		display: flex;
		flex-direction: column;

		width: 100%;
		min-width: 20vw;
		max-width: 20vw;

		overflow-x: hiddenb;
	}

	#nav-list {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		height: 100%;
		width: 100%;
		min-width: 100%;
		max-width: 100%;

		overflow-y: scroll;
	}

	.nav-list-item-text {
		font-size: 3vw;

		text-align: center;
	}

	#nav-list-items {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		width: 100%;
		min-width: 100%;
		max-width: 100%;
	}

	#nav-list-item-button,
	#nav-list-item-button-rfp {
		width: 20vw;
		min-width: 20vw;
		max-width: 20vw;

		display: none;
	}

	#nav-list-item-button-rfp {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.nav-list-item-icon {
		width: stretch;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#nav-dropdown-collapse,
	#nav-dropdown-collapse-rfp {
		padding-left: 0;
	}

	#nav-dropdown-collapse #nav-list-item-button,
	#nav-dropdown-collapse-rfp #nav-list-item-button-rfp {
		background-color: #383838;
	}

	#nav-logo-container {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	#nav-logo {
		height: 50px;
		width: auto;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
}

@media (min-width: 1024px) and (max-width: 1219px) {
}
