#proposalSummaryHelpDialogTitle {
    background-color: #184C93;
    color: white;

}

#proposalSummaryHelpDialogContent {
    display: flex;
    flex-direction: column;
    gap: 1em;

    padding-top: 1em;
    padding-bottom: 1em;
}

.helpParagraph h2 {
    font-size: 20px;
    font-weight: 400;

    color: black;

    padding: 0;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.helpParagraph p {
    font-size: 16px;
    font-weight: 300;

    color: black;

    padding: 0;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

#paragraphHeader {
    margin-top: 1em;
}


.dialogButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;

    margin: 0;
}

