#areYouSureRemoveSpecDialogTitle {
	background-color: #ffa726;
	color: white;
}

#areYouSureRemoveSpecDialogContent {
	display: flex;
	flex-direction: column;
	gap: 1em;

	padding-top: 1em;
	padding-bottom: 1em;

	font-size: 16px;
}

.areYouSureRemoveSpecDialogRow {
	display: flex;
	justify-content: space-between;
	gap: 1em;

	font-weight: 500;
}

.areYouSureRemoveSpecDialogSpecNameContainer {
	display: flex;
	flex-direction: column;
	gap: 1em;
}

.areYouSureRemoveSpecDialogSpecName {
	display: flex;
	gap: 0.5em;
}

.areYouSureRemoveSpecDialogSpecNameDivision {
	color: gray;
}

.areYouSureRemoveSpecDialogSpecNameTitle {
	color: #000;
}

.dialogButtons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 1em;

	margin: 0;
}

.loadingSpinnerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}
