.GeneralInformationCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;

    width: stretch;
    
    padding: 1em;
    
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}

.generalInformationCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: stretch;
}

.generalInformationCardHeader h1 {
    font-size: 20px;
    font-weight: 500;

    color: #333333;

    margin: 0;
    padding: 0;
}

.generalInformationCardHeaderButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 1em;
}

.generalInformationCardSubheader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: stretch;
}
    

.generalInformationCardSubheader h1, .generalInformationCardAlertSubheader h1 {
    font-size: 16px;
    font-weight: 400;

    color: #000000;

    margin: 0;
    padding: 0;
}

.generalInformationCardAlertSubheader {
    display: flex;
    flex-direction: column;

    width: stretch;

}

.generalInformationCardAlertSubheader h2 {
    font-size: 16px;
    font-weight: 300;

    color: #000000;

    margin: 0;
    padding: 0;
}

.generalInformationCardAlertContainer {
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    width: stretch;
}


.generalInformationCardRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;

    width: stretch;
}


.numberOfPhasesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;

    width: stretch;
}

.numberOfPhasesContainer h3 {
    font-size: 16px;
    font-weight: 300;
    color: #333333;

    margin: 0;
    padding: 0;
}

.pendingJobReviewScreenPhaseContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-between;
    gap: 1em;

    width: stretch;
}