.CompanyCamInformationCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;

    width: stretch;

    padding: 1em;
    
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}

.ccCardHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 0.25em;

    width: stretch;

    font-size: 20px;
    font-weight: 500;

    color: #333333;
}

.ccPhaseHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: stretch;

    font-size: 16px;
    font-weight: 400;

    color: #000000;

    margin: 0;
    padding: 0;
}


.ccPhaseContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;

    width: stretch;
}

.ccPhaseContainerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: stretch;
}