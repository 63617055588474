.DesignJobSpecificationEdit {
	display: flex;
	flex-direction: column;

	height: 100%;

	padding-left: 2em;
	padding-right: 2em;
}

.DesignJobSpecificationEditHeader {
	margin-bottom: 2vh;
}

.DesignJobSpecificationEditHeaderTitle {
	font-size: 36px;
	font-weight: 500;
}

.DesignJobSpecificationEditHeaderSubtitle {
	font-size: 24px;
	font-weight: 400;

	color: darkgray;
}

.DesignJobSpecificationContent {
	margin-top: 1vh;
	margin-bottom: 1vh;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.DesignJobSpecificationEditContent {
	display: flex;
	flex-direction: column;

	overflow-y: scroll;
}

.DesignJobSpecificationEditLoadingDiv {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 85vh;

	padding: 2em;
}

.DesignJobSpecificationEditContentButtons {
	display: flex;
	justify-content: flex-end;
	gap: 1em;

	margin-top: 1em;
}

.DesignJobSpecificationEditContentAlert {
	flex-grow: 1;
	display: flex;
	align-items: center;
}
