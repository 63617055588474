.RFP {
	display: flex;
	flex-direction: column;

	padding-left: 2em;
	padding-right: 2em;
}

.rfpHeader {
	display: flex;
	align-items: flex-end;

	font-size: 36px;
	font-weight: 500;
}

.rfpHeaderContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	width: stretch;

	height: 10vh;

	margin-bottom: 2vh;
}

.rfaLink {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	gap: 0.5em;

	font-size: 16px;
	font-weight: 300;
	color: #444444;

	margin: 0;
}

.rfpCardHeader {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;

	width: stretch;

	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.rfpCardHeader h2 {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.5em;

	font-size: 20px;
	font-weight: 500;

	margin: 0;
}

.rfpCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-top: 1vh;
	margin-bottom: 1vh;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.rfpCardRow {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: space-between;
	gap: 1em;

	width: 100%;

	margin-top: 1em;
	margin-bottom: 0.5em;
}

#additionalNotesDiv {
	margin-top: 0.5em;
	margin-bottom: 0em;
}

#proposalSourceDiv {
	display: flex;
	flex-direction: column;
	gap: 0;

	margin-bottom: 0.25em;
}

.rfpCardDialogRow {
	display: flex;
	flex-direction: column;

	width: 100%;
}

.searchByRFPCardRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 1em;

	width: 100%;

	margin-top: 1em;
	margin-bottom: 0.5em;
}

#detailedViewCollapse,
#searchBarCollapse,
#dialogCollapse,
#proposalSourceCollapse {
	width: stretch;
}

.rfpCardGroup {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: 100%;
}

#costBasisCardGroup {
	margin-bottom: 0;
}

.formGroupTitle {
	font-size: 16px;
	font-weight: 500;
}

.proposalSourceTitle {
	font-size: 16px;
	font-weight: 500;

	margin-top: 0;
	margin-bottom: 1em;
}

#formGroup,
#otherCollapse,
#serviceInvasiveCollapse {
	margin-left: 1em;
	margin-right: 1em;
}

.rfpButtonContainer {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 1em;

	width: 100%;

	margin-top: 0.5em;
	margin-bottom: 1em;
}

.rfpSubmitButtonContainer {
	position: relative;
}

.costBasisContainer {
	padding-top: 0.25em;
	padding-bottom: 0.25em;
}

.costBasisContainerMessage {
	font-weight: 500;

	margin-left: 1em;
	margin-top: 0.5em;
}

.proposalSourceCollapseContainer {
	display: flex;
	flex-direction: row;

	padding-top: 1.25em;

	width: stretch;
}

#otherServiceCollapseContainer,
#otherComponentCollapseContainer,
#serviceInvasiveCollapseContainer {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

#searchByFormControl {
	width: 20%;
}

@media (min-width: 320px) and (max-width: 767px) {
	.RFP {
		padding-left: 1em;
		padding-right: 1em;
	}

	.rfpHeader {
		height: 7.5vh;
		margin-bottom: 1vh;

		font-size: 24px;
	}

	.rfpCardHeader {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2vh;

		width: stretch;

		margin-top: 0.5em;
		margin-bottom: 0.5em;
	}

	#bottomRFPCardRow,
	#propertyColumnRFPCardRow,
	#clientSearchByFields {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		width: 100%;

		margin-top: 1em;
		margin-bottom: 0.5em;
	}

	#searchByFormControl {
		width: stretch;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
}

@media (min-width: 1024px) and (max-width: 1219px) {
}
