.DetailScreen {
    display: flex;
    flex-direction: column;

    height: 100%;

    margin-left: 2em;
    margin-right: 2em;
}

.detailScreenHeader {
    display: flex;
    flex-direction: column;

    height: 15vh;
}

.detailScreenHeaderTitle {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    font-size: 36px;
    font-weight: 500;

    height: 10vh;
}

.detailScreenBody {
    display: flex;
    flex-direction: column;

    height: 85vh;

    padding: 2vh 1em;

    overflow-y: scroll;
}

.loadingDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 85vh;

    padding: 2em;
}

.detailScreenDeleteButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;

    margin: 1em 0;
}