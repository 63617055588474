.TipsAndTricksPreviewCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	width: stretch;

	margin-top: 1vh;
	margin-bottom: 1vh;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.TipsAndTricksPreviewCard:hover {
	background-color: #f0f0f0;
	/* Light grey color on hover */
	cursor: pointer;
	/* Changes the mouse cursor to indicate clickable area */
}

.tipsAndTricksPreviewHeader {
	display: flex;
	flex-direction: row;
	align-items: flex-start;

	width: stretch;

	margin-top: 0.5em;
	margin-bottom: 0.5em;
}

.leftSideCardHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 1em;
}

.cardTitles {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5em;
}

.cardTitles h2 {
	font-size: 20px;
	font-weight: 500;

	margin: 0;
	padding: 0;
}

.cardTitles h3 {
	font-size: 16px;
	font-weight: 400;
	color: #666666;

	margin: 0;
	padding: 0;
}
