.SpecificationListItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;

	user-select: none; /* Prevent text selection */
}

.contentContainer {
	display: flex;
	/* flex-direction: column; Stacks the division and title vertically */
}

.division {
	color: gray;
}

.title {
	margin-left: 0.5em;
}

.iconContainer {
	display: flex;
	justify-content: space-between;
	gap: 1em;
}

#editIcon,
#removeIcon {
	cursor: pointer;
}
