#unableToDeleteSpecDialogTitle {
	background-color: #f2bc77;
	color: white;
}

#unableToDeleteSpecDialogContent {
	display: flex;
	flex-direction: column;
	gap: 1em;

	padding-top: 1em;
	padding-bottom: 1em;

	font-size: 16px;
}

.unableToDeleteSpecDialogRow {
	display: flex;
	justify-content: space-between;
	gap: 1em;
	font-weight: 500;
}

.unableToDeleteSpecDialogColumn .unableToDeleteSpecDialogRow {
	font-weight: 400;
}

.unableToDeleteSpecDialogColumn {
	display: flex;
	flex-direction: column;
	gap: 1em;
}

.dialogButtons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 1em;

	margin: 0;
}

.loadingSpinnerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}
