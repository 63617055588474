.JobTitlesConsole {
    display: flex;
    flex-direction: column;

    height: 100%;

    padding-left: 2em;
    padding-right: 2em;
}

.JobTitlesConsoleHeader {
    display: flex;
    flex-direction: column;

    height: 20vh;
}

.JobTitlesConsoleTitle {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    font-size: 36px;
    font-weight: 500;

    height: 10vh;
}

.JobTitlesConsoleDiv {
    flex-grow: 1;

    /* These 3 lines centers the table */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.loadingDiv {
    flex-grow: 1;

    /* These 3 lines centers the loading circle */
    display: flex;
    align-items: center;
    justify-content: center;

    padding-left: 2em;
    padding-right: 2em;
}

.paginationDiv {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 7.5vh;
}