#documentGenerationOptionsDialogTitle {
	background-color: #184c93;
	color: white;
}

#documentGenerationOptionsDialogContent {
	display: flex;
	flex-direction: column;

	padding-top: 1em;
	padding-bottom: 1em;
}

.documentGenerationOptionsDialogSectionFormData {
	display: flex;
	flex-direction: column;
	gap: 1em;

	width: stretch;
}

.documentGenerationOptionsDialogRow {
	display: flex;
	justify-content: space-between;
	gap: 1em;

	width: stretch;
}

.documentGenerationOptionsDialogAlertContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	width: stretch;

	/* padding-top: 0.5em; */
	padding-bottom: 1em;
}

.documentGenerationOptionsDialogSection {
	display: flex;
	flex-direction: column;

	width: stretch;
}

.documentGenerationOptionsDialogCustomTitleContainer {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	width: stretch;

	padding-top: 1em;
}

.documentGenerationOptionsDialogButtons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 1em;

	margin: 0;
	margin-top: 1em;
}

.loadingBarContainer {
	padding: 16px;
}
