#newDetailDialogTitle {
    background-color: #184C93;
    color: white;

}

#newDetailDialogContent {
    display: flex;
    flex-direction: column;
    gap: 1em;

    padding-top: 1em;
    padding-bottom: 1em;
}

.newDetailDialogRow {
    display: flex;
    justify-content: space-between;
    gap: 1em;
}

.newDetailDialogLabel {
    font-size: 18px;
    font-weight: 500;

    padding: 0;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}


.dialogButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1em;

    margin: 0;
}


.loadingSpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}


#fileUploadRow {
    display: flex;
    justify-content: flex-start;
    gap: 1em;   
}

.fileLabel {
    font-size: 16px;
    font-weight: 400;
}

