.AdminTicketCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: stretch;

    margin-top: 1vh;
    margin-bottom: 1vh;

    padding: 1em;
    
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}

.adminTicketCardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: stretch;
}



.adminTicketCardHeaderTitles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
}

.adminTicketCardHeaderTitle {
    font-size: 20px;
    font-weight: 500;
    color: #184C93;

    margin-top: 0;
    margin-bottom: 0;
}

.adminTicketCardHeaderSubtitle {
    font-size: 16px;
    font-weight: 400;
    color: #828282;

    margin-top: 0;
    margin-bottom: 0;
}


.adminTicketCardHeaderButtons {
    display: flex;
    flex-direction: row;
    gap: 1em;
}


.adminTicketCardBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: stretch;
}


.adminTicketCardBodyRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: stretch;

    margin-top: 1em;
    margin-bottom: 1em;
}


.adminTicketCardBodyRowItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;

    flex: 1;
}

.adminTicketCardBodyRowItemTitle, .adminTicketCardBodyRowItemValue {
    font-size: 16px;
    font-weight: 400;

    margin-top: 0;
    margin-bottom: 0;
}

.adminTicketCardBodyRowItemTitle {
    font-weight: 500;
    color: #184C93;
}


.screenshotContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;

    width: stretch;

    margin-top: 1em;
    margin-bottom: 1em;
}

.screenshotContainer img {
    width: 50%;
    height: auto;
    max-height: 100vh;

    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    border-radius: 0.5em;
}


.commentsContainer {
    display: flex;
    flex-direction: column;
    gap: 1em;

    width: stretch;

    margin-top: 1em;
    margin-bottom: 1em;
}


.dialogButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;

    width: stretch;

    margin-top: 1em;
}