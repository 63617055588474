/* height of editable area fills available container editor is in */
.ck.ck-reset.ck-editor.ck-rounded-corners {
	height: 100%;
	display: flex;
	flex-direction: column;

	.ck-editor__main {
		flex-grow: 1;
		display: flex;

		.ck-editor__editable_inline {
			flex-grow: 1;
		}
	}
}

/* styles */

.ck.ck-content span.bold {
	font-weight: bold;
}

.ck.ck-content span.red {
	color: red;
}

.ck.ck-content span.yellow-highlight {
	background-color: yellow;
}

.ck.ck-content span.gray-highlight {
	background-color: lightgray;
}

.ck.ck-content span.blue-highlight {
	background-color: lightblue;
}

.ck.ck-content span.green-highlight {
	background-color: lightgreen;
}

.ck.ck-content span.pink-highlight {
	background-color: lightpink;
}

.ck.ck-content span.uppercase {
	text-transform: uppercase;
}

.ck.ck-content span.italic {
	font-style: italic;
}

.ck.ck-content span.underline {
	text-decoration: underline;
}

/* table / definition list */

.ck.ck-content .table {
	/* push table to left side */
	margin-left: 0;
}

.ck.ck-content table td p {
	/* on "enter" text isnt super far away from each other within the same cell */
	margin: 0;
	padding: 0;
}

/*  */

/* PAGE STYLES */
.ck.ck-content div.page {
	counter-reset: heading;
}

/* NORMAL */
.ck.ck-content p {
	font-family: "Arial", sans-serif;
	font-size: 10pt;
	font-weight: normal;

	text-align: left;

	color: black;
	line-height: 1.25;
}

/* DOCUMENT TITLE */
.ck.ck-content h1 {
	font-family: "Arial", sans-serif;
	font-size: 10pt;
	font-weight: bold;

	text-align: center;
	text-transform: uppercase;

	color: black;

	margin-bottom: 0pt;
}

/* DOCUMENT SUBTITLE */
.ck.ck-content h2 {
	font-family: "Arial", sans-serif;
	font-size: 10pt;
	font-weight: bold;
	line-height: 1;

	text-align: center;
	text-transform: uppercase;

	color: black;

	margin-top: 0pt;
	margin-bottom: 10pt;
}

/* DOCUMENT END OF SECTION */
.ck.ck-content h3 {
	font-family: "Arial", sans-serif;
	font-size: 10pt;
	font-weight: bold;

	text-align: center;
	text-transform: uppercase;

	color: black;

	margin-top: 10pt;
	margin-bottom: 10pt;
}

/* UNIVERSAL LIST STYLING */
.ck.ck-content span {
	font-family: "Arial", sans-serif;
	font-size: 10pt;
	line-height: 1.25;
	margin-top: 0pt;
	margin-bottom: 10pt;
	padding: 0;
	margin: 0;

	counter-reset: section-header;
}

/* PART HEADINGS*/

.ck.ck-content ol {
	counter-reset: part-header;
	padding-left: 0;
}

.ck.ck-content ol li:before {
	font-family: "Arial", sans-serif;
	font-size: 10pt;
	font-weight: bold;
	line-height: 1;

	width: 0rem;

	content: "PART " counter(part-header) " - ";
	counter-increment: part-header;
	float: left;

	text-indent: -4rem;
}

.ck.ck-content ol li {
	list-style: none;
	vertical-align: center;
	margin-top: 13px;
	margin-bottom: 13px;

	padding-left: 4rem;
}

.ck.ck-content ol > li > p {
	padding: 0;
}

/* SECTION HEADING */

.ck.ck-content ol ol {
	counter-reset: section-header;
}

.ck.ck-content ol ol li:before {
	font-family: "Arial", sans-serif;
	font-size: 10pt;
	font-weight: bold;
	line-height: 1;

	width: 0rem;
	content: counter(part-header) "." counter(section-header);
	counter-increment: section-header;
	float: left;
}

.ck.ck-content ol ol li {
	list-style: none;
	vertical-align: center;
	padding: 0;
}

.ck.ck-content ol ol > li > p {
	padding: 0;
}

/* SUBSECTION */

.ck.ck-content ol ol ol {
	counter-reset: subsection;

	text-transform: none;
}

.ck.ck-content ol ol ol li:before {
	font-family: "Arial", sans-serif;
	font-size: 10pt;
	font-weight: normal;
	line-height: 1;

	text-transform: none;

	width: 0rem;

	content: counter(subsection, upper-alpha) ".";
	counter-increment: subsection;
	float: left;
}

.ck.ck-content ol ol ol li {
	list-style: none;
	vertical-align: center;

	text-transform: none;
}

.ck.ck-content ol ol ol > li > p {
	padding: 0;
}

/* SUBSECTION LIST */

.ck.ck-content ol ol ol ol {
	counter-reset: subsection-list;

	text-transform: none;
	margin-left: 4rem;
}

.ck.ck-content ol ol ol ol li:before {
	font-family: "Arial", sans-serif;
	font-size: 10pt;
	font-weight: normal;
	line-height: 1;

	text-transform: none;

	width: 0rem;

	content: counter(subsection-list) ".";
	counter-increment: subsection-list;
	float: left;
}

.ck.ck-content ol ol ol ol li {
	list-style: none;
	vertical-align: center;

	text-transform: none;
}

.ck.ck-content ol ol ol ol > li > p {
	padding: 0;
}

/* SUBSECTION LIST DETAILS*/

.ck.ck-content ol ol ol ol ol {
	counter-reset: subsection-list-details;

	text-transform: none;
}

.ck.ck-content ol ol ol ol ol li:before {
	font-family: "Arial", sans-serif;
	font-size: 10pt;
	font-weight: normal;
	line-height: 1;

	text-transform: none;

	width: 0rem;

	content: counter(subsection-list-details, lower-alpha) ".";
	counter-increment: subsection-list-details;
	float: left;
}

.ck.ck-content ol ol ol ol ol li {
	list-style: none;
	vertical-align: center;

	text-transform: none;
}

.ck.ck-content ol ol ol ol ol > li > p {
	padding: 0;
}

/* SUB SUBSECTION LIST DETAILS */

.ck.ck-content ol ol ol ol ol ol {
	counter-reset: subSubsection-list-details;

	text-transform: none;
}

.ck.ck-content ol ol ol ol ol ol li:before {
	font-family: "Arial", sans-serif;
	font-size: 10pt;
	font-weight: normal;
	line-height: 1;

	text-transform: none;

	width: 0rem;

	content: counter(subSubsection-list-details) ")";
	counter-increment: subSubsection-list-details;
	float: left;
}

.ck.ck-content ol ol ol ol ol ol li {
	list-style: none;
	vertical-align: center;

	text-transform: none;
}

.ck.ck-content ol ol ol ol ol ol > li > p {
	padding: 0;
}
