.pdfsCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-top: 1vh;
    margin-bottom: 1vh;

    padding: 1em;
    
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}

.pdfsCardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: stretch;
}

.pdfsCardHeaderIconTitleContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;
    
    flex-grow: 1;

    cursor: pointer;
}

.pdfsCardHeaderTitle {
    font-size: 20px;
    font-weight: 500;

    color: #FAAC4A;

    margin: 0;
}


#pdfsDropdownCollapse {
    width: stretch;
}

.pdfsDropdownContent {
    display: flex;
    flex-direction: column;

    width: stretch;

    padding: 1em;
}

.pdfDropdownRow {
    display: flex;
    flex-direction: row;
    gap: 2em;

    width: stretch;
}


.pdfContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    width: stretch;
}

.pdfTitle {
    font-size: 18px;
    font-weight: 500;
    text-align: center;

    color: #000000;

    margin: 0;
}

.pdfPreview {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 70%;
    height: fit-content;
}

.pdfPreview img {
    width: 100%;
    /* aspect-ratio: 17 / 22; */

    object-fit: contain;

    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    border-radius: 0.5em;
}

.pdfDownloadButtonContainer {
    display: flex;
    justify-content: center;

    width: 70%;
}



.cadFileContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.5em;

    width: stretch;
}

.cadFileTitleLabel {
    font-size: 18px;
    font-weight: 500;
}

.downloadButtonContainer {
    padding-top: 0.5em;
}


#cadFileRow {
    margin-top: 2em;
}