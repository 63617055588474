.TipsAndTricksDetails {
    display: flex;
    flex-direction: column;

    padding-left: 2em;
    padding-right: 2em;
}

.tipsAndTricksDetailsHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    font-size: 36px;
    font-weight: 500;

    height: 10vh;

    margin-bottom: 2vh;
}

.tipsAndTricksDetailsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: stretch;
}

#videoCardCategoryContainer {
    width: stretch;
    margin-bottom: 2em;
}

.categoryTitle {
    font-size: 24px;
    font-weight: 500;

    margin-top: 1em;
    margin-bottom: 1em;
}

.loadingSpinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 88vh;
}

#iframeElement {
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #ffffff;

    border-radius: 0.5em;
}