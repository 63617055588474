.ITRequest {
    display: flex;
    flex-direction: column;

    padding-left: 2em;
    padding-right: 2em;
}

.itRequestHeader {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    font-size: 36px;
    font-weight: 500;

    height: 10vh;

    margin-bottom: 2vh;
}

.itRequestCard {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: stretch;

    margin-top: 1vh;
    margin-bottom: 1vh;

    padding: 1em;
    
    box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
    background-color: #FFFFFF;

    border-radius: 0.5em;
}

.itRequestCardRow, .dialogButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;

    width: stretch;

    margin-top: 1vh;
    margin-bottom: 1vh;
}

.itRequestTypeContainer {
    display: flex;
    flex-direction: column;

    flex: 1;
}

.radioGroupTitle, .fileUploadTitle {
    font-size: 18px;
    font-weight: 500;

    margin-bottom: 0.5em;
}


.fileUploadContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    flex: 1;
}

.fileInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;

    margin-top: 1em;
    margin-bottom: 1em;
}

.dialogButtons {
    justify-content: flex-end;
}

.submitButtonContainer {
    margin-top: 0.5em;
    position: relative;
}

#successAlertRow, #errorAlertRow {
    margin-top: 0;
    margin-bottom: 0;
}


.fileUploadContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    flex: 1;
}


.fileUploadButtonTitle {
    display: flex;
    justify-content: flex-start;
    gap: 1em;   
}

.fileLabel {
    font-size: 16px;
    font-weight: 400;
}