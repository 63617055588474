#addSpecDialogTitle {
	background-color: #184c93;
	color: white;
}

#addSpecDialogContent {
	display: flex;
	flex-direction: column;
	/* gap: 1em; */

	padding-top: 1em;
	padding-bottom: 1em;
}

.addSpecDialogRow {
	display: flex;
	justify-content: space-between;
	gap: 1em;
	width: 60vw;
}

.addSpecDialogCheckboxContainer {
	display: flex;
	flex-direction: column;
	gap: 1em;

	height: 50vh;
	overflow-y: auto;

	width: stretch;
}

.dialogButtons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 1em;

	margin: 0;
}

.loadingSpinnerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.addSpecificationDescription {
	font-size: 0.875rem;
	color: gray;
}

.addSpecificationDivision {
	color: gray;
	padding-right: 0.25em;
}

.addSpecificationRowOne {
	display: flex;
}

.addSpecificationTitle {
	padding-left: 0.25em;
}
