.specDetailScreenListCard {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	margin-top: 1vh;
	margin-bottom: 1vh;

	padding: 1em;

	box-shadow: 0px 5px 15px rgb(0, 0, 0, 0.35);
	background-color: #ffffff;

	border-radius: 0.5em;
}

.specContentScreenListCardHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;

	/* Sticky positioning */
	/* position: sticky;
	top: -2vh;
	z-index: 100;
	background-color: #ffffff; */
}

.specDetailScreenListCardHeaderIconTitleContainer {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5em;

	flex-grow: 1;

	cursor: pointer;
}

.specDetailScreenListCardHeaderTitle {
	font-size: 20px;
	font-weight: 500;

	color: #184c93;

	margin: 0;
}

#contentDropdownCollapse {
	width: stretch;
}

.contentDropdownContent {
	display: flex;
	flex-direction: column;
	gap: 2em;

	width: stretch;

	padding-top: 1em;
	padding-bottom: 1em;
}

.SpecDetailScreenContentCardButtonGroup {
	display: flex;
	gap: 10px;
}

.SpecDetailScreenContentCardButtonGroupView {
	font-weight: bold;
}
